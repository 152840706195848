import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
  client_id: window.CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login`,
  response_type: 'token',
  scope: window.OAUTH2_SCOPE,
  authority: window.AUTH_URL,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

userManager.events.addSilentRenewError(() => {
  userManager.getUser();
});

export default userManager;
