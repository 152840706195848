import { useContext } from 'react';
import { DownloadALertContext } from 'context/DownloadALertContext';
import { OPEN_DOWNLOAD_ALERT } from 'context/DownloadALertContext/constants';

const useDownloadAlert = () => {
  const { state, dispatch } = useContext(DownloadALertContext);

  const setOpenDialog = data => dispatch({
    type: OPEN_DOWNLOAD_ALERT,
    isOpen: data,
  });
  return {
    ...state,
    setOpenDialog,
  };
};

export default useDownloadAlert;
