import Events from 'assets/images/statsIcons/driving_events.svg';
import Recalls from 'assets/images/statsIcons/recalls.svg';
import Maintenance from 'assets/images/statsIcons/maintenance.svg';
import HarshAccel from 'assets/images/icons/harsh_aceleration.svg';
import HarshBraking from 'assets/images/icons/harsh_braking.svg';
import SpeedingDistance from 'assets/images/statsIcons/speeding_distance.svg';
import DistanceIcon from 'assets/images/statsIcons/distance.svg';
import DurationIcon from 'assets/images/statsIcons/duration.svg';
import MaxSpeedIcon from 'assets/images/statsIcons/max_speed.svg';
import FuelUsed from 'assets/images/statsIcons/fuel_used.svg';
import PhoneEvents from 'assets/images/statsIcons/phone_events.svg';
import IdlingTime from 'assets/images/statsIcons/idle_time.svg';
import Boundary from 'assets/images/statsIcons/boundary.svg';
import CrashReport from 'assets/images/statsIcons/crash_report.svg';
import Panic from 'assets/images/statsIcons/panic.svg';
import DriveTime from 'assets/images/statsIcons/drive_time.svg';
import HarshTurn from 'assets/images/statsIcons/harsh_turn.svg';
import PhoneUsage from 'assets/images/statsIcons/phone_usage.svg';
import PhoneCall from 'assets/images/statsIcons/phone_call.svg';
import Mileage from 'assets/images/statsIcons/mileage.svg';
import Idling from 'assets/images/statsIcons/idling.svg';
import TripStart from 'assets/images/icons/marker_start.svg';
import TripStop from 'assets/images/icons/marker_stop.svg';
import Score from 'assets/images/statsIcons/current_score.svg';
import Unplug from 'assets/images/statsIcons/unplug.svg';
import Dtc from 'assets/images/statsIcons/dtc.svg';
import Engine from 'assets/images/statsIcons/engine.svg';
import Battery from 'assets/images/statsIcons/battery.svg';
import Tires from 'assets/images/statsIcons/tires.svg';
import Seatbelt from 'assets/images/statsIcons/seatbelt.svg';
import Tooltag from 'assets/images/icons/bluetooth_tooltag.svg';
import DriverID from 'assets/images/icons/driver_id.svg';
import Refuel from 'assets/images/icons/refuel.svg';
import DriverDetected from 'assets/images/icons/driver_detected.svg';
import Bump from 'assets/images/icons/bump.svg';
import CelullarSignalLost from 'assets/images/icons/celullar_signal_lost.svg';
import SdCardRemoved from 'assets/images/icons/sd_card_removed.svg';
import SdCardInserted from 'assets/images/icons/sd_card_inserted.svg';
import DoorAlarm from 'assets/images/icons/door_alarm.svg';
import DriverNotDetected from 'assets/images/icons/driver_not_detected.svg';

export const STAT_ICONS = {
  events: Events,
  recalls: Recalls,
  maintenance: Maintenance,
  harshAcceleration: HarshAccel,
  harshBraking: HarshBraking,
  speedingDistance: SpeedingDistance,
  distance: DistanceIcon,
  duration: DurationIcon,
  maxSpeed: MaxSpeedIcon,
  fuelUsed: FuelUsed,
  phoneEvents: PhoneEvents,
  idlingTime: IdlingTime,
  crashReport: CrashReport,
  panic: Panic,
  boundary: Boundary,
  driveTime: DriveTime,
  harshTurn: HarshTurn,
  phoneCalls: PhoneCall,
  phoneUsage: PhoneUsage,
  mileage: Mileage,
  idling: Idling,
  tripStart: TripStart,
  tripStop: TripStop,
  score: Score,
  unplug: Unplug,
  dtc: Dtc,
  engine: Engine,
  battery: Battery,
  tires: Tires,
  seatbelt: Seatbelt,
  tooltag: Tooltag,
  refuel: Refuel,
  driverDetected: DriverDetected,
  bump: Bump,
  driverId: DriverID,
  cellullarSignalLost: CelullarSignalLost,
  sdCardRemoved: SdCardRemoved,
  sdCardInserted: SdCardInserted,
  doorAlarm: DoorAlarm,
  driverNotDetected: DriverNotDetected,
  cameraObscured: Events,
};
