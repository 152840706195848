import {
  SET_LOADING_POINTS,
  SET_POINTS,
  SET_OPEN_POINT
} from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING_POINTS:
      return { ...state, isLoading: action.isLoading };
    case SET_POINTS:
      return { ...state, points: action.points };
    case SET_OPEN_POINT:
      return { ...state, openPoint: action.point };
    default:
      return state;
  }
};

export default reducer;
