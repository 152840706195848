import { set } from 'lodash';
import {
  INCREASE_DISTANCE_BY,
  SET_VEHICLE,
  SET_DRIVING_DATA,
  SET_DRIVING_VEHICLE,
  REMOVE_PARKED_VEHICLE,
  CLEAR_VEHICLE,
  SET_DEVICE_DATA
} from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_VEHICLE:
      return { ...state, vehicle: action.payload };
    case INCREASE_DISTANCE_BY: {
      const cloneState = { ...state };
      set(cloneState, 'drivingData.distance', state.drivingData.distance + action.payload);
      return cloneState;
    }
    case SET_DRIVING_DATA:
      return { ...state, drivingData: { ...state.drivingData, ...action.payload } };
    case SET_DEVICE_DATA:
      return { ...state, deviceData: { ...state.deviceData, ...action.payload } };
    case SET_DRIVING_VEHICLE: {
      const newVehicles = new Map(state.drivingVehicles);
      newVehicles.set(action.payload.id, action.payload.data);
      return { ...state, drivingVehicles: newVehicles };
    }
    case REMOVE_PARKED_VEHICLE: {
      const newVehicles = new Map(state.drivingVehicles);
      newVehicles.delete(action.payload);
      return { ...state, drivingVehicles: newVehicles };
    }
    case CLEAR_VEHICLE:
      return {
        ...state, vehicle: {},
      };
    default:
      return state;
  }
};

export default reducer;
