import { useContext } from 'react';
import { TripsContext } from 'context/TripsContext';
import
{
  TRIP,
  TRIPS,
  TRIPMAP,
  TRIPMAP_UPDATE,
  TRIP_SELECTIONS,
  TRIP_POINTS_MAP,
  TRIP_POINTS_MAP_UPDATE,
  VEHICLE
} from 'context/TripsContext/constants';

const useEldFilter = () => {
  const { state, dispatch } = useContext(TripsContext);
  const setTrip = tripItem => dispatch({ type: TRIP, tripItem });
  const loadingTripMap = () => dispatch({ type: TRIPMAP_UPDATE });
  const loadingTripsPointMap = () => dispatch({ type: TRIP_POINTS_MAP_UPDATE });
  const addTripMap = tripsMap => dispatch({ type: TRIPMAP, tripsMap });
  const setTrips = (trips, loading) => dispatch({ type: TRIPS, trips, loading });
  const setTripSelections = tripIdMap => dispatch({ type: TRIP_SELECTIONS, tripIdMap });
  const addTripPointMap = tripsPointMap => dispatch({ type: TRIP_POINTS_MAP, tripsPointMap });
  const setVehicle = vehicle => dispatch({ type: VEHICLE, vehicle });

  return {
    tripsLoading: state.tripsLoading,
    trips: state.trips,
    tripsMap: state.tripsMap,
    tripsMapReady: state.tripsMapReady,
    tripIdMap: state.tripIdMap,
    loadingTripMap,
    addTripMap,
    setTrip,
    setTrips,
    setTripSelections,
    addTripPointMap,
    loadingTripsPointMap,
    tripsPointMap: state.tripsPointMap,
    tripsPointsMapReady: state.tripsPointsMapReady,
    vehicle: state.vehicle,
    setVehicle,
  };
};

export default useEldFilter;
