import React, { memo } from 'react';
import LoaderIcon from 'assets/images/icons/loader.svg';
import classNames from 'classnames';
import st from './index.scss';

const Loader = ({ className }) => (
  <div className={classNames(st.wp, className)}>
    <LoaderIcon />
  </div>
);

export default memo(Loader);
