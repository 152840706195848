import React, { useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  instanceMap: null,
  mode: null,
  clusterMode: true,
  mapContainer: null,
  isUserInteraction: false,
  isRealPointsShown: false,
  openVehicleId: null,
  clusterZoom: { zoom: false, cluster: false, newData: {} },
  mapZoom: 5,
  mapCenter: {},
};

const MapContext = React.createContext(initialState);

const MapProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <MapContext.Provider value={{ state, dispatch }}>{props.children}</MapContext.Provider>;
};

export { MapContext, MapProvider };
