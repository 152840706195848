import React, { createContext, useState } from 'react';

const RavenContext = createContext({});

const RavenProvider = props => {
  const [isOpenRavenCamera, setIsOpenRavenCamera] = useState(false);

  const toggleRavenCamera = () => setIsOpenRavenCamera(!isOpenRavenCamera);
  const turnOffCamera = () => setIsOpenRavenCamera(false);

  const values = {
    toggleRavenCamera,
    isOpenRavenCamera,
    turnOffCamera,
  };

  return <RavenContext.Provider value={values}>{props.children}</RavenContext.Provider>;
};

export { RavenContext, RavenProvider };
