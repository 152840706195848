import { omit } from 'lodash';
import userManager from 'utils/userManager';
import
{
  SET_SYSTEM_SETTINGS,
  SET_CURRENT_USER,
  SET_ASSIGNED_VEHICLES_COUNT,
  SET_VEHICLES_WITH_LOCATION_COUNT,
  LOGOUT,
  SET_REALTIME_SOCKET,
  CLEAR_REALTIME_SOCKET,
  AUTHORIZE
} from './constants';

export const initialState = {
  systemSettings: {
    defaultCountry: 'us',
    onlyCountries: ['us'],
  },
  user: null,
  realtimeSocket: null,
  permissions: [],
  assignedVehiclesCount: 0,
  vehiclesWithLocationCount: 0,
  isLogged: false,
};

export default (state, action) => {
  switch (action.type) {
    case AUTHORIZE:
      userManager.signinRedirect({
        extraQueryParams: {
          email: 'XYZ',
        },
      });
      return state;
    case SET_SYSTEM_SETTINGS:
      return { ...state, systemSettings: action.systemSettings };
    case SET_ASSIGNED_VEHICLES_COUNT:
      return { ...state, assignedVehiclesCount: action.assignedVehiclesCount };
    case SET_VEHICLES_WITH_LOCATION_COUNT:
      return { ...state, vehiclesWithLocationCount: action.vehiclesWithLocationCount };
    case SET_CURRENT_USER: {
      const { user } = action;
      window.localStorage.setItem('userViewSetting', JSON.stringify(omit(user.userViewSetting, ['id', '__typename'])));
      return {
        ...state,
        user,
        permissions: Object.keys(user.permissions).filter(item => user.permissions[item]),
      };
    }
    case LOGOUT:
      window.localStorage.clear();
      window.sessionStorage.clear();
      userManager.signinRedirect();
      return initialState;
    case SET_REALTIME_SOCKET:
      return {
        ...state,
        realtimeSocket: action.realtimeSocket,
      };
    case CLEAR_REALTIME_SOCKET:
      return {
        ...state,
        realtimeSocket: null,
      };
    default:
      return state;
  }
};
