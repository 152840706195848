import { useContext } from 'react';
import { RealTimeVehicleDataStoreContext, allVehiclesMapObject } from 'context/RealTimeVehicleDataStoreContext';
import {
  ADD_UPDATE_VEHICLE_INFO,
  CLEAR_VEHICLES_STORE,
  UPDATE_VEHICLE_LIST,
  ADD_ALL_TAGS
} from 'context/RealTimeVehicleDataStoreContext/constant';

const useRealTimeVehicleStore = () => {
  const { state, dispatch } = useContext(RealTimeVehicleDataStoreContext);

  const updateRealTimeDataInVehiclesMap = (id, data) => dispatch({
    type: ADD_UPDATE_VEHICLE_INFO,
    payload: { id, data },
  });
  const clearVehiclesMap = () => dispatch({ type: CLEAR_VEHICLES_STORE });
  const updateVehiclesList = () => dispatch({ type: UPDATE_VEHICLE_LIST });
  const addAllTags = allTags => dispatch({ type: ADD_ALL_TAGS, payload: { allTags } });
  return {
    ...state,
    updateRealTimeDataInVehiclesMap,
    clearVehiclesMap,
    updateVehiclesList,
    addAllTags,
    allVehiclesMapObject,
  };
};

export default useRealTimeVehicleStore;
