import React, { useReducer } from 'react';
import reducer from './reducer';

const initialState = { alerts: [] };
const AlertsContext = React.createContext(initialState);

const AlertsProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AlertsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AlertsContext.Provider>
  );
};

export { AlertsContext, AlertsProvider };
