import { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';
import
{
  SET_SYSTEM_SETTINGS,
  SET_CURRENT_USER,
  SET_ASSIGNED_VEHICLES_COUNT,
  SET_VEHICLES_WITH_LOCATION_COUNT,
  LOGOUT,
  SET_REALTIME_SOCKET,
  CLEAR_REALTIME_SOCKET,
  AUTHORIZE
} from 'context/AuthContext/constants';

const useAuth = () => {
  const { state, dispatch } = useContext(AuthContext);

  const setSystemSettings = systemSettings => dispatch({ type: SET_SYSTEM_SETTINGS, systemSettings });

  const setCurrentUser = user => dispatch({ type: SET_CURRENT_USER, user });

  const setAssignedVehiclesCount = assignedVehiclesCount => dispatch({
    type: SET_ASSIGNED_VEHICLES_COUNT,
    assignedVehiclesCount,
  });

  const setVehiclesWithLocationCount = vehiclesWithLocationCount => dispatch({
    type: SET_VEHICLES_WITH_LOCATION_COUNT,
    vehiclesWithLocationCount,
  });

  const logout = () => dispatch({ type: LOGOUT });

  const setRealtimeSocket = realtimeSocket => dispatch({ type: SET_REALTIME_SOCKET, realtimeSocket });

  const clearRealtimeSocket = () => dispatch({ type: CLEAR_REALTIME_SOCKET });

  const authorize = () => dispatch({ type: AUTHORIZE });

  const isLogged = () => {
    const isToken = !!window.localStorage.getItem('access_token');
    const expiresAt = +window.localStorage.getItem('expires_at');
    const isExpired = expiresAt ? new Date(expiresAt) > new Date() : true;
    return isToken && !isExpired;
  };
  const user = state.user ? {
    ...state.user,
    assignedVehiclesCount: state.assignedVehiclesCount,
    vehiclesWithLocationCount: state.vehiclesWithLocationCount,
  } : state.user;

  return {
    user,
    systemSettings: state.systemSettings,
    realtimeSocket: state.realtimeSocket,
    permissions: state.permissions,
    isLogged: isLogged(),
    setSystemSettings,
    setCurrentUser,
    logout,
    setRealtimeSocket,
    clearRealtimeSocket,
    setAssignedVehiclesCount,
    setVehiclesWithLocationCount,
    authorize,
  };
};

export default useAuth;
