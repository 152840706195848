import React, { memo, useContext, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { ModalContainer } from 'react-router-modal';
import { compose } from 'react-apollo';
import { get } from 'lodash';
import { MapProvider } from 'context/MapContext';
import { DarkModeContext } from 'context/DarkModeContext/DarkModeContext';

import { useAuth, useDownloadAlert } from 'hooks';
import { RealTimeVehicleDataStoreProvider } from 'context/RealTimeVehicleDataStoreContext';
import { GeofenceProvider } from 'context/GeofenceContext';
import { AddressProvider } from 'context/AddressContext';
import st from './index.scss';
import AllVehiclesRealTimeDataStore from './Fleet/routes/Map/AllVehiclesRealTimeDataStore';
const AlertNotifications = React.lazy(() => import('containers/Alerts'));
const Header = React.lazy(() => import('components/Header'));
const WhatsNew = React.lazy(() => import('components/WhatsNew'));
const Footer = React.lazy(() => import('components/Footer'));
const ReportsDownloadAlert = React.lazy(() => import('components/ReportsDownloadAlert'));
const Support = React.lazy(() => import('./Support'));
const Auth = React.lazy(() => import('./Auth'));
const Reports = React.lazy(() => import('./Reports'));
const Maintenance = React.lazy(() => import('./Maintenance'));
// const Stats= React.lazy(() => import('./Stats'));;
const Settings = React.lazy(() => import('./Settings'));
const Fleet = React.lazy(() => import('./Fleet'));
const Profile = React.lazy(() => import('./Profile'));
const Alerts = React.lazy(() => import('./Alerts'));
const SafetyCoach = React.lazy(() => import('./SafetyCoach'));
const Announcement = React.lazy(() => import('./Announcement'));
const WhatsNewPage = React.lazy(() => import('./WhatsNew'));
const Documents = React.lazy(() => import('./Documents'));
const ConfirmPage = React.lazy(() => import('./ConfirmPage'));
const ShareTrack = React.lazy(() => import('./ShareTrack'));

const PAGE_WITHOUT_HEADER = /(\/auth\/(invite|forgot|confirm))|share_track/;

// TODO: return Stats section in future releases
const Routes = ({ location }) => {
  const { theme } = useContext(DarkModeContext);
  const { user } = useAuth();
  const {
    isOpen,
    setOpenDialog,
  } = useDownloadAlert();
  const isShowHeader = !location.pathname.match(PAGE_WITHOUT_HEADER);
  const isShowFooter = !location.pathname.match(/share_track/);
  let email = '';
  let pass = '';

  let isAccessSafetyCoach = false;
  if (user) {
    isAccessSafetyCoach = user.permissions.vehicleManagement ?
      (user.permissions.safetyCoach && user.account.safetyCoachEnabled) : user.permissions.vehicleDrive;
  }
  const fleetRoute = get(user, 'permissions.vehicleManagement') ? '/fleet' : '/my_trips';

  if (window.location.pathname !== '/share_track') {
    sessionStorage.removeItem('share_track_token');
  }

  const shareTrackToken = sessionStorage.getItem('share_track_token');

  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams) {
    email = queryParams.get('u');
    pass = queryParams.get('p');
  }

  const validateCredentials = () => (email && email.length > 0) && (pass && pass.length > 0);
  const renderRoutes = () => {
    if (shareTrackToken) {
      return (
        <Switch>
          <Route path="/share_track" component={ShareTrack} />
          <Redirect to="/share_track" />
        </Switch>
      );
    }
    if (!user || validateCredentials()) {
      return (
        <Switch>
          <Route path="/auth" component={Auth} key="auth" />
          <Route path="/support" component={Support} key="support" />
          <Route path="/shared_track" component={Support} key="shared_track" />
          <Redirect to="/auth" key="redirect_to" />
        </Switch>
      );
    }

    const isNewDocumentsAvailable = get(user, 'newPrivateDocumentsAvailable');
    const newDocumentVersions = (get(user, 'newPrivateDocumentVersions') || [])
      .sort((a, b) => get(a, 'privateDocument.priority', 1) - get(b, 'privateDocument.priority', 1));
    const currentDocumentName = get(newDocumentVersions, '[0].privateDocument.name', '');

    if (isNewDocumentsAvailable && !user.signedInAsAnotherUser) {
      return (
        <Switch>
          <Route path="/documents" component={Documents} />
          <Route exact path="/support" component={Support} />
          <Redirect to={`/documents/preview/${currentDocumentName}`} />
        </Switch>
      );
    }

    const handleReportRout = user.permissions.reportsAccess || user.permissions.fleetManagementAccess;

    return (
      <>
        <AddressProvider>
          <RealTimeVehicleDataStoreProvider>
            {isOpen && (
              <ReportsDownloadAlert
                modalClose={() => {
                  setOpenDialog(false);
                }}
              />
            )}
            <Switch>
              <Route path="/alerts" component={Alerts} />
              {user.permissions.maintenanceAccess &&
                user.account.maintenanceEnabled && <Route path="/maintenance" component={Maintenance} />}
              <Route
                path={fleetRoute}
                render={props => (
                  <>
                    <AllVehiclesRealTimeDataStore />
                    <Fleet {...props} />
                  </>
                )}
              />

              {!user.permissions.fleetManagementAccess && <Route path="/reports" component={Reports} />}
              {handleReportRout && <Route path="/reports" component={Reports} />}
              {!user.permissions.fleetManagementAccess && <Route path="/settings" component={Settings} />}
              {user.permissions.vehicleManagement && <Route path="/settings" component={Settings} />}
              {!user.permissions.fleetManagementAccess && <Route path="/profile" component={Profile} />}
              {isAccessSafetyCoach && <Route path="/safety_coach" component={SafetyCoach} />}
              <Route path="/support" component={Support} />
              <Route path="/announcement/:id" component={Announcement} />
              <Route path="/whats_new" component={WhatsNewPage} />
              <Redirect to={`${fleetRoute}/map`} />
            </Switch>
          </RealTimeVehicleDataStoreProvider>
        </AddressProvider></>
    );
  };

  return (
    <MapProvider>
      <GeofenceProvider>
        <Suspense
          fallback={(
            <div className="loading-container">
              <div className="loader"></div>
              <div className="loader"></div>
              <div className="loader"></div>
            </div>
          )}
        >
          {isShowHeader && <Header />}
          <main className={st.main} data-theme={theme}>

            <AlertNotifications />
            <Switch>
              <Route path="/auth/confirm" component={ConfirmPage} />
              {renderRoutes()}
            </Switch>
            <WhatsNew />
          </main>
          {isShowFooter && user && <Footer user={user} />}

          <ModalContainer />
        </Suspense>
      </GeofenceProvider>
    </MapProvider>
  );
};

export default compose(
  memo,
  withRouter,
)(Routes);
