import React, { useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  isOpen: false,
};

const DownloadALertContext = React.createContext(initialState);

const DownloadALertProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <DownloadALertContext.Provider value={{ state, dispatch }}>{props.children}</DownloadALertContext.Provider>;
};

export { DownloadALertContext, DownloadALertProvider };
