import { useContext } from 'react';
import { RealTimeContext } from 'context/RealTimeContext';
import {
  SET_VEHICLE,
  CLEAR_VEHICLE,
  INCREASE_DISTANCE_BY,
  SET_DRIVING_DATA,
  SET_DEVICE_DATA,
  SET_DRIVING_VEHICLE,
  REMOVE_PARKED_VEHICLE
} from 'context/RealTimeContext/constants';

const useRealTime = () => {
  const { state, dispatch } = useContext(RealTimeContext);

  const setVehicle = vehicle => dispatch({ type: SET_VEHICLE, payload: vehicle });
  const clearVehicle = () => dispatch({ type: CLEAR_VEHICLE });
  const increaseDistanceBy = value => dispatch({ type: INCREASE_DISTANCE_BY, payload: value });
  const setDrivingData = data => dispatch({ type: SET_DRIVING_DATA, payload: data });
  const setDeviceData = data => dispatch({ type: SET_DEVICE_DATA, payload: data });
  const setDrivingVehicle = (id, data) => dispatch({ type: SET_DRIVING_VEHICLE, payload: { id, data } });
  const removeParkedVehicle = id => dispatch({ type: REMOVE_PARKED_VEHICLE, payload: id });
  return {
    ...state,
    setVehicle,
    clearVehicle,
    increaseDistanceBy,
    setDrivingData,
    setDeviceData,
    setDrivingVehicle,
    removeParkedVehicle,
  };
};

export default useRealTime;
