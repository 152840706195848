
import React, { createContext, useState } from 'react';

const VehiclesContext = createContext({});

const VehiclesProvider = props => {
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [getVehiclesIsLoading, setGetVehiclesIsLoading] = useState(false);
  const [socketLoaded, setSocketLoaded] = useState(false);
  const [vehiclesListIsLoading, setVehiclesListIsLoading] = useState(false);

  const handleSetTotalVehicles = quantity => {
    setTotalVehicles(quantity);
  };

  const values = {
    totalVehicles,
    setTotalVehicles,
    handleSetTotalVehicles,

    getVehiclesIsLoading,
    setGetVehiclesIsLoading,
    vehiclesListIsLoading,
    setVehiclesListIsLoading,
    socketLoaded,
    setSocketLoaded,
  };

  return (
    <VehiclesContext.Provider value={values}>{props.children}</VehiclesContext.Provider>
  );
};

export { VehiclesContext, VehiclesProvider };
