import { addLocaleData } from 'react-intl';
import appLocaleDataEn from 'react-intl/locale-data/en';
import enMessages from 'LOCALE_PATH/en_US.json';

const enLang = {
  messages: {
    ...enMessages,
  },
  locale: 'en-US',
  data: appLocaleDataEn,
};

const AppLocale = {
  en: enLang,
};

addLocaleData(AppLocale.en.data);

export default AppLocale;
