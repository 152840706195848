import { useRef, useEffect } from 'react';

const usePortal = () => {
  const rootElemRef = useRef(null);
  useEffect(() => {
    document.body.appendChild(rootElemRef.current);
    return () => {
      if (rootElemRef.current) {
        if (Element.prototype.remove) {
          rootElemRef.current.remove();
        } else document.body.removeChild(rootElemRef.current); // IE hack
      }
    };
  }, []);

  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }

    return rootElemRef.current;
  };

  return getRootElem();
};

export default usePortal;
