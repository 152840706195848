import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'UNION',
          name: 'AdditionalData',
          possibleTypes: [
            {
              name: 'AdditionalDataEmail',
            },
            {
              name: 'AdditionalDataPhone',
            },
            {
              name: 'AdditionalDataLoginCompleted',
            },
            {
              name: 'AdditionalDataConfiguredMethods',
            }
          ],
        },
        {
          kind: 'UNION',
          name: 'SecondFactorMethodState',
          possibleTypes: [
            {
              name: 'RecoveryCodesState',
            },
            {
              name: 'OtpState',
            },
            {
              name: 'SmsOtpState',
            }
          ],
        }
      ],
    },
  },
});

export default fragmentMatcher;
