import gql from 'graphql-tag';
import { Fragments } from 'requests/fragments';

export const getSearchFilter = gql(`
  query searchFilter($text: String!) {
    searchFilter(text: $text, types: [BOUNDARY, PLACE, TAG, TRACKING_OBJECT_TYPE]) {
      ... on Boundary {
        id
        name
      }
      ... on Place {
        id
        name
      }
      ... on TrackingObjectType {
        id
        name
      }
      ... on Tag {
        id
        name
        tagType {
          id
          name
        }
      }
    }
  }
`);

export const getTagsQuery = gql`
  query getTags($pagination: PaginationParams, $searchValue: String, $filter: TagFilter) {
    tags(pagination: $pagination, searchValue: $searchValue, filter: $filter) {
      entities {
        id
        name   
        assigned
        tagType {
          id
          name
        }
      }
      pagination {
        ...Pagination
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const getTagQuery = gql`
  query getTag($id: ID!) {
    tag(id: $id) {
      id
      name   
      assigned
      tagType {
        id
        name
      }
      vehicles {
        id
        nickname
        make
        model
        year
        vin
        licensePlate
      }
      drivers {
        user {
          firstName
          lastName
        }
      }
      assets {
        id
        name
      }
    }
  }
`;

export const getTagsExtendedQuery = gql`
  query getTagsExtendedQuery($searchValue: String) {
    extendedTags(searchValue: $searchValue) {
      id
      name   
      assigned
      tagType {
        id
        name
      }
      vehicles {
        id
        nickname
        make
        model
        year
        vin
        licensePlate
      }
      assets {
        id
        name
      }
    }
  }
`;

export const getTagExtendedQuery = gql`
  query getTagExtendedQuery($id: ID!) {
    tag(id: $id) {
      id
      name   
      assigned
      tagType {
        id
        name
      }
      vehicles {
        id
        nickname
        make
        model
        year
        vin
        licensePlate
      }
      assets {
        id
        name
      }
    }
  }
`;
export const getTagTypesQuery = gql`
  query getTagTypes($pagination: PaginationParams, $searchValue: String) {
    tagTypes(pagination: $pagination, searchValue: $searchValue) {
      entities {
        id
        name   
      }
      pagination {
        ...Pagination
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const createTagType = gql(`
  mutation createTagType($name: String) {
    createTagType(name: $name) {
      id
      name
    }
  }
`);

export const createTag = gql(`
  mutation createTag(
    $name: String!
    $tagTypeId: ID!
    $vehicleIds: [ID]
    $assetIds: [ID]
    $driverIds: [ID]
  ) {
    createTag(
      name: $name
      tagTypeId: $tagTypeId
      vehicleIds: $vehicleIds
      assetIds: $assetIds
      driverIds: $driverIds
    ) {
      id
      name
      assigned
    }
  }
`);

export const updateTag = gql(`
  mutation updateTag(
    $id: ID!
    $name: String!
    $tagTypeId: ID!
    $vehicleIds: [ID]
    $assetIds: [ID]
    $driverIds: [ID]
  ) {
    updateTag(
      id: $id
      name: $name
      tagTypeId: $tagTypeId
      vehicleIds: $vehicleIds
      assetIds: $assetIds
      driverIds: $driverIds
    ) {
      id
      name
      assigned
    }
  }
`);

export const deleteTag = gql(`
  mutation deleteTag($id: ID!) {
    deleteTag(id: $id) {
      status
    }
  }
`);
