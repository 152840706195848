import React, { useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  tags: new Map(), // compose from Map Containers. Example: new Map([ ['Status', ['driving': {label: ..., name: ....}, ...] ], ... ])
  typeFilter: new Map(),
  drivingStatuses: [],
  vehicleAsset: [],
  health: [],
};

const TagFilterContext = React.createContext(initialState);

const TagFilterProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <TagFilterContext.Provider value={{ state, dispatch }}>{props.children}</TagFilterContext.Provider>;
};

export { TagFilterContext, TagFilterProvider };
