import React, { createContext, useState } from 'react';

const DarkModeContext = createContext({});

const dark = 'dark';

const currentTheme = 'currentTheme';
const themeStoraged = localStorage.getItem('theme');

const DarkModeProvider = props => {
  const [theme, setTheme] = useState(themeStoraged || currentTheme);

  const toggleDarkMode = () => {
    const themeVariable = theme === currentTheme ? dark : currentTheme;
    localStorage.setItem('theme', themeVariable);
    setTheme(themeVariable);
    document.body.setAttribute('data-theme', themeVariable);
  };

  const isDark = () => theme === dark;

  const values = {
    theme,
    toggleDarkMode,
    isDark,
  };

  return <DarkModeContext.Provider value={values}>{props.children}</DarkModeContext.Provider>;
};

export { DarkModeContext, DarkModeProvider };
