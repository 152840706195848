
import { useAuth } from 'hooks';
import React, { createContext, useEffect, useState } from 'react';
import { compose, withApollo } from 'react-apollo';
import { getLocations } from 'requests/settings/locations';

const AddressContext = createContext({});

const AddressProvider_ = props => {
  const [places, setPlaces] = useState([]);
  const { user } = useAuth();

  const getLocationsPlaces = async (address = '') => {
    try {
      const { data: { places: locations } } = await props.client.query({
        query: getLocations,
        variables: {
          pagination: { page: 1 },
          searchValue: address,
        },
      });

      const { pagination, entities } = locations;
      if (pagination.perPage < pagination.totalEntries) {
        const { data: { places: locations_ } } = await props.client.query({
          query: getLocations,
          variables: {
            pagination: { page: 1, perPage: pagination.totalEntries },
            searchValue: address,
          },
        });
        setPlaces(locations_.entities);
      } else setPlaces(entities);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (user) {
      getLocationsPlaces();
    }
  }, []);

  return (
    <AddressContext.Provider value={{ places }}>
      {props.children}
    </AddressContext.Provider>
  );
};
const enhance = compose(withApollo);
const AddressProvider = enhance(AddressProvider_);
export { AddressContext, AddressProvider };

