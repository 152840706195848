import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from './user';
import auth from './auth';

const combinedReducer = history => combineReducers({
  router: connectRouter(history),
  user,
  auth,
});

export default combinedReducer;
