import { OPEN_DOWNLOAD_ALERT } from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case OPEN_DOWNLOAD_ALERT:
      return { ...state, isOpen: action.isOpen };
    default:
      return state;
  }
};

export default reducer;
