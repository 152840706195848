import React, { memo } from 'react';
import { CallbackComponent } from 'redux-oidc';
import { useAuth } from 'hooks'
import { Suspense } from 'react'
const Loader = React.lazy(() => import('components/Loader'));
import userManager from 'utils/userManager';

const AuthCallback = ({ history }) =>
{
  const { authorize } = useAuth();
  return (
    <Suspense fallback={<div className="loading-container">
      <div className="loader"></div>
      <div className="loader"></div>
      <div className="loader"></div>
    </div>}>
      <CallbackComponent
        userManager={userManager}
        successCallback={() =>
        {
          const redirectTo = '/fleet/map';
          history.replace(window.localStorage.getItem('redirect_to') || redirectTo);
          window.localStorage.removeItem('redirect_to');
        }}
        errorCallback={error =>
        {
          //eslint-disable-next-line no-console
          console.warn('Error in AuthCallback - redirect to auth', error);
          authorize();
        }}
      >
        <Loader />
      </CallbackComponent>
    </Suspense>
  );
};

export default memo(AuthCallback);
