import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'hooks'
import { Suspense } from 'react'
const Button = React.lazy(() => import('components/Button'));
import Logo from 'IMGS_PATH/logo.svg';
import st from './index.scss';

const Welcome = ({ history }) =>
{
  const { isLoggedIn, authorize } = useAuth();

  const handleRedirect = () =>
  {
    if (isLoggedIn)
    {
      history.push('/fleet/map');
    } else authorize();
  };

  return (
    <Suspense fallback={<div className="loading-container">
      <div className="loader"></div>
      <div className="loader"></div>
      <div className="loader"></div>
    </div>}>
      <div className={st.loginScreen}>
        <div className={st.welcome}>
          <FormattedMessage id="app.routes.auth.authWelcome" />
        </div>
        <div className={st.logo}>
          <Logo />
        </div>
        <Button onClick={handleRedirect}>
          <FormattedMessage id="app.routes.auth.login" />
        </Button>
      </div></Suspense>
  );
};

export default withRouter(Welcome);
