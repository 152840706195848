import React from 'react';
import { FormattedMessage } from 'react-intl';
import variables from 'STYLES_PATH/_assets.scss';

export const DRIVING = 'DRIVING';
export const IDLING = 'IDLING';
export const INSTALLED = 'INSTALLED';
export const PARKED = 'PARKED';
export const PARKED_WITH_ALERT = 'PARKED_WITH_ALERT';
export const SPEEDING = 'SPEEDING';
export const TOWING = 'TOWING';
export const UNKNOWN = 'UNKNOWN';
export const UNPLUGGED = 'UNPLUGGED';
export const WAITING_FOR_DATA = 'WAITING_FOR_DATA';
export const PING = 'PING';

export const TRIP_STATUSES_XG = ['IMPOSSIBLE', 'NORMAL', 'SHORT', 'UNUSUAL'];

// all statuses except UNKNOWN
export const STATUSES_WITH_LOCATION = [DRIVING, IDLING, INSTALLED, PARKED, PARKED_WITH_ALERT,
  SPEEDING, TOWING, UNPLUGGED, WAITING_FOR_DATA, PING];

export const DRIVING_STATUSES = [
  {
    label: <FormattedMessage id="app.common.popup.drivingStatus.DRIVING" />,
    value: DRIVING,
    styles: { color: variables.driving },
  },
  {
    label: <FormattedMessage id="app.common.popup.drivingStatus.WAITING_FOR_DATA" />,
    value: WAITING_FOR_DATA,
    styles: { color: variables.waiting_for_data },
  },
  {
    label: <FormattedMessage id="app.common.popup.drivingStatus.UNPLUGGED" />,
    value: UNPLUGGED,
    styles: { color: variables.unplugged },
    htmlLabel: 'Device Unplugged',
  },
  {
    label: <FormattedMessage id="app.common.popup.drivingStatus.IDLING" />,
    value: IDLING,
    styles: { color: variables.idling },
  },
  {
    label: <FormattedMessage id="app.common.popup.drivingStatus.PARKED" />,
    value: [PARKED, PARKED_WITH_ALERT],
    styles: { color: variables.parked },
  },
  {
    label: <FormattedMessage id="app.common.popup.drivingStatus.UNKNOWN" />,
    value: UNKNOWN,
    styles: { color: variables.unknown },
    htmlLabel: 'Ready to drive',
  },
  {
    label: <FormattedMessage id="app.common.popup.drivingStatus.PING" />,
    value: PING,
    styles: { color: variables.ping },
  },
];
