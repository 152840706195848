// media files
export const VIDEO = 'VIDEO';
export const SNAPSHOT = 'SNAPSHOT';
export const MP4 = 'MP4';
export const MDT = 'MDT';
export const JPG = 'JPG';
export const PNG = 'PNG';
// media source
export const EVENT = 'EVENT';
export const MANUAL = 'MANUAL';
export const IPCAM = 'IPCAM';

// media statuses
export const PENDING = 'PENDING';
export const ENQUEUED = 'ENQUEUED';
export const REQUESTED = 'REQUESTED';
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const TIMEOUTED = 'TIMEOUTED';

// camera types
export const OWL = 'OWL';
export const SMARTWITNESS = 'SMARTWITNESS';
export const RAVEN = 'RAVEN';

export const PENDING_STATUSES = [PENDING, ENQUEUED, REQUESTED];
export const ERROR_STATUSES = [FAILED, TIMEOUTED];
