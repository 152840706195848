import React, { useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  geofenceList: [],
  updateFlag: false,
};

const GeofenceContext = React.createContext(initialState);

const GeofenceProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <GeofenceContext.Provider value={{ state, dispatch }}>{props.children}</GeofenceContext.Provider>;
};

export { GeofenceContext, GeofenceProvider };
