import
{
  SET_INSTANCE_MAP,
  CLEAR_INSTANCE_MAP,
  SET_MODE,
  SET_CLUSTER_MODE,
  SET_USER_INTERACTION,
  SET_MAP_CONTAINER_REF,
  CLEAR_MAP_CONTAINER_REF,
  SET_IS_SHOWN_REAL_POINTS,
  OPEN_VEHICLE_MARKER,
  CLUSTER_ZOOM,
  MAP_ZOOM,
  MAP_CENTER,
  SHOW_TOOLTAGS
} from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_INSTANCE_MAP:
      return { ...state, instanceMap: action.instanceMap };
    case CLEAR_INSTANCE_MAP:
      return { ...state, instanceMap: null };
    case SET_MODE:
      return { ...state, mode: action.mode };
    case SET_CLUSTER_MODE:
      return { ...state, clusterMode: action.mode };
    case SET_USER_INTERACTION:
      return { ...state, isUserInteraction: action.isUserInteraction };
    case SET_MAP_CONTAINER_REF:
      return { ...state, mapContainer: action.mapContainer };
    case CLEAR_MAP_CONTAINER_REF:
      return { ...state, mapContainer: null };
    case SET_IS_SHOWN_REAL_POINTS:
      return { ...state, isRealPointsShown: action.isRealPointsShown };
    case OPEN_VEHICLE_MARKER:
      return { ...state, openVehicleId: action.openVehicleId };
    case CLUSTER_ZOOM:
      return { ...state, clusterZoom: action.clusterZoom };
    case MAP_ZOOM:
      return { ...state, mapZoom: action.mapZoom };
    case MAP_CENTER:
      return { ...state, mapCenter: action.mapCenter };
    case SHOW_TOOLTAGS:
      return { ...state, showTooltags: action.showTooltags };

    default:
      return state;
  }
};

export default reducer;
