import gql from 'graphql-tag';
import { Fragments } from '../fragments';

export const getLocations = gql`
  query places($pagination: PaginationParams, $searchValue: String) {
    places(pagination: $pagination, searchValue: $searchValue) {
      entities {
        id
        address
        latitude
        longitude
        name
        radius
      }
      pagination {
        ...Pagination
      }
    }
  }
  ${Fragments.PaginationFragment}
`;

export const getLocation = gql(`
  query place($id: ID!) {
    place(id: $id) {
      id
      address
      latitude
      longitude
      name
      radius
    }
  }
`);

export const getLocationName = gql(`
query reversePlace($lat: Float!, $lng: Float!) {
  reversePlace(latitude: $lat, longitude: $lng) {
    name
  }
}
`);

export const addLocation = gql(`
  mutation createPlace(
    $address: String!,
    $latitude: Float!,
    $longitude: Float!,
    $name: String!,
    $radius: Float!
  )
  {
    createPlace(
      address: $address,
      latitude: $latitude,
      longitude: $longitude,
      name: $name,
      radius: $radius
    )
    {
      id
      address
      latitude
      longitude
      name
      radius
    }
  }
`);

export const deleteLocation = gql(`
  mutation deletePlace($id: ID!) {
    deletePlace(id: $id) {
      id
    }
  }
`);

export const updateLocation = gql(`
  mutation updateLocation(
    $id: ID!,
    $address: String,
    $latitude: Float,
    $longitude: Float,
    $name: String,
    $radius: Float
  )
  {
    updatePlace(
      id: $id,
      address: $address,
      latitude: $latitude,
      longitude: $longitude,
      name: $name,
      radius: $radius
    )
    {
      id
      address
      latitude
      longitude
      name
      radius
    }
  }
`);

export const geocoding = gql(`
  query reverseGeocoding($lat: Float!, $lng: Float!) {
    reverseGeocoding(lat: $lat, lng: $lng) {
      city
      country
      countryCode
      fullAddress
      houseNumber
      latitude
      longitude
      postalCode
      state
      stateCode
      street
    }
  }
`);

export const geoPointBasedOnAddress = gql(`
  query geocoding($address: String!) {
    geocoding(address: $address) {
      latitude
      longitude
    }
  }
`);

export const uploadLocations = gql(`
  mutation importPlaces(
    $file: Upload!
  )
  {
    importPlaces(
      file: $file
    )
    {
      invalid {
      documentRowNumber
      field
      value
      message
      }
    validCount
    invalidCount
    }
  }
`);

export const radarReverseGeocoding = gql(`
query radarReverseGeocoding($lat: Float!, $lng: Float!) {
  radarReverseGeocoding(lat: $lat, lng: $lng)
}
`);

export const hereMapsReverseGeocoding = gql(`
  query hereMapsReverseGeocoding($lat: Float!, $lng: Float!) {
    hereMapsReverseGeocoding(lat: $lat, lng: $lng) 
  }
`);
