import { pick } from 'lodash';
import { getValue } from 'utils/global';
import { FEATURE_ACCESS } from 'constants/ManagementAccess';
import useAuth from './useAuth';

const accessAllowed = ['eldEnabled', 'maintenanceEnabled', 'cameraPolicyLevel', 'safetyCoachEnabled', 'gorillaEldEnabled', 'apolloEldEnabled'];
//TODO: refactor login with account access
const useAccess = () => {
  const { user } = useAuth();
  // TODO: change this logic
  const hasUserAccess = true;
  const access = pick(getValue(user, 'account', {}), accessAllowed);
  const allowed = Object.keys(access).filter(key => access[key]);

  const allowedItems = menu => menu.filter(item => {
    if (item.access === FEATURE_ACCESS.cameraPolicyLevel) {
      return allowed.includes(item.access) && hasUserAccess;
    }
    return allowed.includes(item.access) || item.access === 'all';
  });
  return {
    access,
    allowed,
    allowedItems,
    hasUserAccess,
  };
};

export default useAccess;
