import React, { useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  isLoading: false,
  points: [],
  openPoint: null,
};

const RealPointsContext = React.createContext(initialState);

const RealPointsProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <RealPointsContext.Provider value={{ state, dispatch }}>{props.children}</RealPointsContext.Provider>;
};

export { RealPointsContext, RealPointsProvider };
