import { useContext } from 'react';
import { MapContext } from 'context/MapContext';
import
{
  CLEAR_INSTANCE_MAP,
  SET_INSTANCE_MAP,
  SET_MODE,
  SET_CLUSTER_MODE,
  SET_USER_INTERACTION,
  SET_MAP_CONTAINER_REF,
  CLEAR_MAP_CONTAINER_REF,
  SET_IS_SHOWN_REAL_POINTS,
  OPEN_VEHICLE_MARKER,
  CLUSTER_ZOOM,
  MAP_ZOOM,
  MAP_CENTER,
  SHOW_TOOLTAGS
} from 'context/MapContext/constants';

const useMap = () => {
  const { state, dispatch } = useContext(MapContext);

  const setInstanceMap = instanceMap => dispatch({ type: SET_INSTANCE_MAP, instanceMap });

  const clearInstanceMap = () => dispatch({ type: CLEAR_INSTANCE_MAP });

  const setMode = mode => dispatch({ type: SET_MODE, mode });

  const setClusterMode = mode => dispatch({ type: SET_CLUSTER_MODE, mode });

  const setUserInteraction = isUserInteraction => dispatch({ type: SET_USER_INTERACTION, isUserInteraction });

  const setMapContainerRef = mapContainer => dispatch({ type: SET_MAP_CONTAINER_REF, mapContainer });

  const clearMapContainerRef = () => dispatch({ type: CLEAR_MAP_CONTAINER_REF, mapContainer: null });

  const setIsShownRealPoints = isRealPointsShown => dispatch({ type: SET_IS_SHOWN_REAL_POINTS, isRealPointsShown });

  const openVehicleMarker = openVehicleId => dispatch({ type: OPEN_VEHICLE_MARKER, openVehicleId });

  const setClusterZoom = clusterZoom => dispatch({ type: CLUSTER_ZOOM, clusterZoom });

  const setMapZoom = mapZoom => dispatch({ type: MAP_ZOOM, mapZoom });

  const setMapCenter = mapCenter => dispatch({ type: MAP_CENTER, mapCenter });

  const setShowTooltags = showTooltags => dispatch({ type: SHOW_TOOLTAGS, showTooltags });
  return {
    ...state,
    setInstanceMap,
    clearInstanceMap,
    setMode,
    setClusterMode,
    setUserInteraction,
    setMapContainerRef,
    clearMapContainerRef,
    setIsShownRealPoints,
    openVehicleMarker,
    setClusterZoom,
    setMapZoom,
    setMapCenter,
    setShowTooltags,
  };
};

export default useMap;
