import React, { useReducer } from 'react';
import reducer, { allVehiclesMapObject } from './reducer';

const initialState = {
  updatedVehicleList: [],
  allVehiclesMapObject: new Map(),
  initialLoading: true,
  allTags: new Map(),
  tagsLoaded: false,
};

const RealTimeVehicleDataStoreContext = React.createContext(initialState);

const RealTimeVehicleDataStoreProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <RealTimeVehicleDataStoreContext.Provider value={{ state, dispatch }}>
      {props.children}
    </RealTimeVehicleDataStoreContext.Provider>
  );
};

export { RealTimeVehicleDataStoreContext, RealTimeVehicleDataStoreProvider, allVehiclesMapObject };
