import { useContext } from 'react';
import { AlertsContext } from 'context/AlertsContext';

const useAlert = () => {
  const { state, dispatch } = useContext(AlertsContext);
  const openAlert = (message = 'Done', variant = 'success') => dispatch({
    type: 'ALERT',
    message,
    variant,
  });

  const closeAlert = id => dispatch({
    type: 'CLOSE_ALERT',
    id,
  });

  return {
    alerts: state ? state.alerts : [],
    openAlert,
    closeAlert,
  };
};

export default useAlert;
