import { useContext } from 'react';
import { TagFilterContext } from 'context/TagFilterContext';
import { SET_TAG_FILTER, ADD_TAG_FILTER_TYPE, SET_TYPE_FILTER, SET_STATUS, SET_HEALTH, SET_VEHICLE_ASSET } from 'context/TagFilterContext/constants';

const useTagFilter = () => {
  const { state, dispatch } = useContext(TagFilterContext);

  const setTagFilter = tags => (dispatch({ type: SET_TAG_FILTER, tags }));

  const addTagFilterType = option => dispatch({ type: ADD_TAG_FILTER_TYPE, tagType: option.tagType, value: option.value, key: option.key });

  const setTypeFilter = typeFilter => (dispatch({ type: SET_TYPE_FILTER, typeFilter }));

  const setStatusFilter = status => dispatch({ type: SET_STATUS, status });

  const setHealthFilter = health => dispatch({ type: SET_HEALTH, health });

  const setVehicleAsset = vehicleAsset => dispatch({ type: SET_VEHICLE_ASSET, vehicleAsset });

  const getTagIds = () => Array.from(state.tags.keys()).reduce((accum, cur) => (
    [...accum, ...Array.from(state.tags.get(cur).keys())]
  ), []);

  return {
    tags: state.tags,
    typeFilter: state.typeFilter,
    drivingStatuses: state.drivingStatuses,
    vehicleOrAsset: state.vehicleOrAsset,
    health: state.health,
    getTagIds,
    setTagFilter,
    addTagFilterType,
    setTypeFilter,
    setStatusFilter,
    setHealthFilter,
    setVehicleAsset,
  };
};

export default useTagFilter;
