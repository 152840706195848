import { ALERT, CLOSE_ALERT } from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: Date.now(),
            message: action.message,
            variant: action.variant,
          }
        ],
      };
    case CLOSE_ALERT: {
      return {
        ...state,
        alerts: state.alerts.filter(alert => alert.id === action.id),
      };
    }
    default: return state;
  }
};

export default reducer;
