import React, { useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  trips: [],
  tripsMap: {},
  tripsLoading: false,
  tripsMapReady: true,
  vehicle: null,
  tripIdMap: {},
  tripsPointsMapReady: true,
  tripsPointMap: {},
};

const TripsContext = React.createContext(initialState);

const TripsProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <TripsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </TripsContext.Provider>
  );
};

export { TripsContext, TripsProvider };
