import {
  SET_TAG_FILTER,
  ADD_TAG_FILTER_TYPE,
  SET_TYPE_FILTER,
  SET_STATUS,
  SET_VEHICLE_ASSET,
  SET_HEALTH
} from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_TAG_FILTER:
      return { ...state, tags: new Map(action.tags) };
    case SET_TYPE_FILTER:
      return { ...state, tags: new Map(action.typeFilter) };
    case ADD_TAG_FILTER_TYPE: {
      let currentTags = state.tags.get(action.tagType);
      if (currentTags) currentTags.set(action.key, action.value);
      else currentTags = new Map([[action.key, action.value]]);
      return { ...state, tags: new Map(state.tags.set(action.tagType, currentTags)) };
    }
    case SET_STATUS:
      return { ...state, drivingStatuses: action.status };
    case SET_VEHICLE_ASSET:
      return { ...state, vehicleOrAsset: action.vehicleAsset };
    case SET_HEALTH:
      return { ...state, health: action.health };
    default:
      return state;
  }
};

export default reducer;
