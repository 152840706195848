import React, { useReducer } from 'react';
import reducer, { initialState } from './reducer';

const AuthContext = React.createContext(initialState);

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
