import React, { memo, useEffect, useState, useContext } from 'react';
import { compose, withApollo } from 'react-apollo';
import { set, get, isNumber, isNull } from 'lodash';
import { getErrorMessages, getVehicleStatus, getDateTimeDiff } from 'utils/global';
import { useAlert, useAuth, useRealTimeVehicleStore } from 'hooks';
import { DRIVING_STATUS_TIMESTAMP, DESC, DAY } from 'constants/Global';
import { getDriverById, getDriverIdByBleNumber, getVehiclesMapQuery, updateVehicleDriver } from 'requests/settings/vehicles';
import { getTagsExtendedQuery } from 'requests/settings/tags';
import { VehiclesContext } from 'context/VehiclesContext';
import { withRouter } from 'react-router';
import { TRIP_STATUSES_XG } from 'constants/TripStatus';
import moment from 'moment';
import { Socket } from 'phoenix';
import { generateCustomRealtimeTokenMultipleVehicles } from 'requests/fleet';
const vehiclesList = [];
const PARKED_STATUSES = ['parked', 'parked_with_alert'];
const AllVehiclesRealTimeDataStore = ({ client, location }) => {
  const countPerPage = 100;
  const { openAlert: alert } = useAlert();
  const { user: { account } } = useAuth();
  const {
    updateRealTimeDataInVehiclesMap,
    allVehiclesMapObject,
    clearVehiclesMap,
    updateVehiclesList,
    addAllTags,
  } = useRealTimeVehicleStore();

  const { setGetVehiclesIsLoading, setSocketLoaded } = useContext(VehiclesContext);

  const [isSocketInit, setIsSocketInit] = useState(false);
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    /* eslint-disable no-await-in-loop */
    (async () => {
      if (channels.length > 0) {
        for (let d = 0; d < channels.length; d++) {
          await channels[d].leave();
          setIsSocketInit(false);
        }
        setChannels([]);
      }

      if (location.pathname === '/fleet/map') {
        await loadVehicles(1);
        if (!isSocketInit) setupChannel(vehiclesList);
      }
    })();
  }, [location]);

  useEffect(() => {
    getAllTags();
  }, []);

  const getRealtimeTokenVehicles = async vehicleIds => {
    const expiryDate = new Date();
    const totalTimeExpyre = new Date().setHours(new Date().getHours() + 15);
    const expiresAt = Math.round((totalTimeExpyre - expiryDate.getTime()) / 1000);

    return client.query({
      query: generateCustomRealtimeTokenMultipleVehicles,
      variables: {
        expiresAt,
        notes: '',
        email: account.billingEmail,
        vehicleIds,
        from: '',
      },
    });
  };

  const initSocket = async vehiclesId => {
    const { data: { generateCustomRealtimeTokenMultipleVehicles } } = await getRealtimeTokenVehicles(vehiclesId);
    const socket = new Socket(`${window.REALTIME_URL}/api/v1/realtime`, {
      params: {
        token: generateCustomRealtimeTokenMultipleVehicles.token,
      },
    });
    socket.connect();
    return socket;
  };

  /* eslint-disable no-await-in-loop */
  const setupChannel = async vehicles => {
    setIsSocketInit(true);
    const maxOpts = 80;
    const vehsId = vehicles.map(veh => parseInt(veh.id));
    const channels_ = [];

    for (let x = 0; x < vehicles.length; x += maxOpts) {
      let channel = null;
      const vehsInitData = vehicles.slice(x, x + maxOpts);
      const vehs_ = vehsId.slice(x, x + maxOpts);
      const newSocket = await initSocket(vehs_);
      const channelName = `v1/tracking_object:accessible`;//:${x}`;
      channel = newSocket.channel(channelName);
      channel.on('new_location', onNewLocation);
      channel.on('new_status_change', onNewStatusChange);
      channel.on('new_status', onNewStatus);
      channel.join();

      channels_.push(channel);

      await initializeData(vehsInitData.filter(vehicle => (vehicle || {}).trackingObjectUuid), channel);
    }

    updateVehiclesList();
    setSocketLoaded(true);
    setChannels(channels_);
  };

  const initializeData = (vehicles, channel) => Promise.all(vehicles.filter(vehicle => !isNull(vehicle))
    .map(v => requestCurrentData(v, true, channel))).catch(e => e);

  const requestCurrentData = (vehicle, force = false, channel) => new Promise((resolve, reject) => {
    const { trackingObjectUuid } = vehicle;
    updateRealTimeDataInVehiclesMap(vehicle.trackingObjectUuid, vehicle);
    if (!channel) reject(new Error('No channel connection'));
    // if channel is created already then promise is resolved
    if (allVehiclesMapObject.has(trackingObjectUuid) && !force) return resolve({ needRequest: false });

    channel.push('request_current_data', { tracking_object_uuid: trackingObjectUuid }, 10000000)
      .receive('ok', res => resolve({ res, needRequest: true }))
      .receive('error', reasons => reject(reasons))
      .receive('timeout', () => reject(new Error('reasons timeout')));
  }).then(async ({ res, needRequest }) => {
    if (!needRequest) return;

    let bleDriverId = {};
    let newVehicle = { ...vehicle };

    if (res.status &&
      res.status &&
      res.status.change_point &&
      res.status.change_point.data &&
      res.status.change_point.data.bluetooth_driver_id_tag) {
      const { status: { change_point: { data: { bluetooth_driver_id_tag: bluetoothDriverIdTag } } } } = res;
      bleDriverId = bluetoothDriverIdTag;
      newVehicle = await reAsignDriver({ ...vehicle }, bleDriverId);
    }

    let statusVehicle = getVehicleStatus(res, newVehicle);
    let statusTimestamp = get(res, 'status.change_timestamp', null) || res.updated_at;
    if (newVehicle.isAsset && newVehicle.assetStatus && newVehicle.assetStatus.timestamp) {
      statusTimestamp = newVehicle.assetStatus.timestamp;
    } else if (!newVehicle.isAsset && statusVehicle === 'driving') {
      const startTime = (newVehicle || {}).lastRealtimeTrip ? (newVehicle.lastRealtimeTrip || {}).startTime : null;
      if (startTime) {
        statusTimestamp = startTime;
      }
    }
    if (!newVehicle.isAsset && statusVehicle === 'driving' &&
      statusTimestamp && getDateTimeDiff(statusTimestamp, new Date(), 'hours') > 12) {
      statusVehicle = 'parked_with_alert';
    }
    set(newVehicle, 'drivingStatus', statusVehicle.toUpperCase());
    set(newVehicle, 'drivingStatusTimestamp', statusTimestamp);
    set(newVehicle, 'displayedTimestamp', res.location.timestamp);
    if (!PARKED_STATUSES.includes(statusVehicle)) {
      set(newVehicle, 'location', get(res, 'location.point.location'));
      const lastPointData = get(res, 'last_point.data', null);
      const drivingData = getRealtimeData(lastPointData, newVehicle);
      set(drivingData, 'drivingStatus', statusVehicle.toUpperCase());
      set(drivingData, 'drivingStatusTimestamp', statusTimestamp);
      set(drivingData, 'displayedTimestamp', res.location.timestamp);
      set(drivingData, 'location', get(res, 'location.point.location'));
      updateRealTimeDataInVehiclesMap(newVehicle.trackingObjectUuid, { ...newVehicle, ...drivingData });
    } else {
      const lastPointData = get(res, 'last_point.data', null);
      const drivingData = getRealtimeData(lastPointData, newVehicle);
      set(drivingData, 'location', get(res, 'location.point.location'));
      updateRealTimeDataInVehiclesMap(newVehicle.trackingObjectUuid, { ...newVehicle, ...drivingData });
    }
    return newVehicle;
  }).catch(err => console.error(err)); //eslint-disable-line no-console

  const onNewStatus = async response => {
    const mobileId = response.tracking_object_uuid;

    let timestampStatus = get(response, 'current_timestamp');

    let bleDriverId = {};

    if (response.current_point &&
      response.current_point.data &&
      response.current_point.data.bluetooth_driver_id_tag) {
      const { current_point: { data: { bluetooth_driver_id_tag: bluetoothDriverIdTag } } } = response;
      bleDriverId = bluetoothDriverIdTag;
    }

    const newVehicle = await reAsignDriver(vehiclesList.find(vehicle => vehicle.trackingObjectUuid === mobileId), bleDriverId);
    const { current_point: currentPoint } = response;
    if (currentPoint) {
      const { data: dataDriverDetected, events } = currentPoint;
      if (events.includes('driver_detected') && dataDriverDetected) {
        await reAssignDriverByDriveDetected(dataDriverDetected.driver_id, response.tracking_object_uuid);
      }
    }

    if (!newVehicle) return;

    const status = get(response, 'status');

    let newStatus = status;

    const nVeh = allVehiclesMapObject.get(mobileId);

    if (nVeh) {
      if (nVeh.isAsset && nVeh.assetStatus) {
        if (nVeh.assetStatus.reported !== status) {
          nVeh.assetStatus.reported = status;
        } else {
          newStatus = nVeh.assetStatus.actual;
          if (nVeh.assetStatus.timestamp) {
            timestampStatus = nVeh.assetStatus.timestamp;
          }
        }
      } else if (!nVeh.isAsset) {
        if (status === 'driving' && !PARKED_STATUSES.includes(get(response, 'from_status', ''))) {
          const startTime = (nVeh || {}).lastRealtimeTrip ? (nVeh.lastRealtimeTrip || {}).startTime : null;
          if (startTime) {
            timestampStatus = startTime;
          }
          if (timestampStatus && getDateTimeDiff(timestampStatus, new Date(), 'hours') > 12) {
            newStatus = 'parked_with_alert';
          }
        }
      }
    }
    set(newVehicle, 'drivingStatus', newStatus.toUpperCase());
    set(newVehicle, 'drivingStatusTimestamp', timestampStatus);
    const data = get(response, 'current_point.data', null);
    const drivingData = { ...newVehicle, ...getRealtimeData(data, newVehicle) };
    updateRealTimeDataInVehiclesMap(newVehicle.trackingObjectUuid, drivingData, true);
  };

  const onNewStatusChange = async response => {
    const mobileId = response.tracking_object_uuid;
    let timestampStatus = get(response, 'change_timestamp');
    let bleDriverId = {};

    if (response.last_point &&
      response.last_point &&
      response.last_point.data &&
      response.last_point.data.bluetooth_driver_id_tag) {
      const { status: { change_point: { data: { bluetooth_driver_id_tag: bluetoothDriverIdTag } } } } = response;
      bleDriverId = bluetoothDriverIdTag;
    }

    const newVehicle = await reAsignDriver(vehiclesList.find(vehicle => vehicle.trackingObjectUuid === mobileId), bleDriverId);

    if (!newVehicle) return;
    const status = get(response, 'to_status');
    let newStatus = status;
    const nVeh = allVehiclesMapObject.get(mobileId);
    if (nVeh) {
      if (nVeh.isAsset && nVeh.assetStatus) {
        if (nVeh.assetStatus.reported !== status) {
          nVeh.assetStatus.reported = status;
        } else {
          newStatus = nVeh.assetStatus.actual;
          if (nVeh.assetStatus.timestamp) {
            timestampStatus = nVeh.assetStatus.timestamp;
          }
        }
      } else if (!nVeh.isAsset) {
        if (status === 'driving' && !PARKED_STATUSES.includes(get(response, 'from_status', ''))) {
          const startTime = (nVeh || {}).lastRealtimeTrip ? (nVeh.lastRealtimeTrip || {}).startTime : null;
          if (startTime) {
            timestampStatus = startTime;
          }
          if (timestampStatus && getDateTimeDiff(timestampStatus, new Date(), 'hours') > 12) {
            newStatus = 'parked_with_alert';
          }
        }
      }
    }
    set(newVehicle, 'drivingStatus', newStatus.toUpperCase());
    set(newVehicle, 'drivingStatusTimestamp', timestampStatus);
    const data = get(response, 'change_point.data', null);
    const drivingData = { ...newVehicle, ...getRealtimeData(data, newVehicle) };
    updateRealTimeDataInVehiclesMap(newVehicle.trackingObjectUuid, drivingData, true);
  };

  const reAssignDriverByDriveDetected = async (driverId, trackingObjectUuid) => {
    const { data: { driver } } = await client.query({
      query: getDriverById,
      variables: {
        id: driverId,
      },
    });

    const vehicle = [...allVehiclesMapObject].find(veh => veh[0] === trackingObjectUuid);

    updateRealTimeDataInVehiclesMap(trackingObjectUuid, { ...vehicle, currentDriver: driver });

    if (vehicle) {
      await client.mutate({
        mutation: updateVehicleDriver,
        variables: {
          id: driverId,
          vehicleId: vehicle[1].id,
        },
      });
    }
  };

  const reAsignDriver = async (newDriverVeh, bleDriverIdResponse) => {
    const newDriverVeh_ = { ...newDriverVeh };
    if (bleDriverIdResponse && JSON.stringify(bleDriverIdResponse) !== '{}') {
      let { currentDriver } = newDriverVeh;

      if (currentDriver === null) {
        try {
          const { data: { findByBleDriveridNumber } } = await client.query({
            query: getDriverIdByBleNumber,
            variables: {
              driveridNumber: bleDriverIdResponse.number.toString(),
            },
          });
          currentDriver = findByBleDriveridNumber;
        } catch (errors) {
          getErrorMessages(errors).forEach(error => alert(error, 'error'));
        }
      }

      const { bleDriverId, id: currentDriverId } = currentDriver || {};

      if (bleDriverId && (bleDriverIdResponse.number !== bleDriverId.number)) {
        const oldVehicle_ = [...allVehiclesMapObject].find(vehObj => vehObj[1].currentDriver &&
          vehObj[1].currentDriver.bleDriverId &&
          vehObj[1].currentDriver.bleDriverId.number === bleDriverIdResponse.number);

        if (oldVehicle_) {
          const [trackObjIdVehicleNotDriver, oldVeh] = oldVehicle_;
          newDriverVeh_.currentDriver = oldVeh.currentDriver ? {
            ...oldVeh.currentDriver,
          } : { ...currentDriver };

          if (oldVeh) {
            updateRealTimeDataInVehiclesMap(trackObjIdVehicleNotDriver, { ...oldVeh, currentDriver: null });
          }
        }
        await client.mutate({
          mutation: updateVehicleDriver,
          variables: {
            id: currentDriverId,
            vehicleId: newDriverVeh_.id,
          },
        });
      }
    }

    return newDriverVeh_;
  };

  const onNewLocation = async response => {
    const mobileId = response.tracking_object_uuid;

    const location = {
      latitude: get(response, 'location_point.location.latitude'),
      longitude: get(response, 'location_point.location.longitude'),
    };
    const displayedTimestamp = get(response, 'location_timestamp', null);

    let bleDriverId = {};

    if (
      response.location_point &&
      response.location_point.data &&
      response.location_point.data.bluetooth_driver_id_tag) {
      const { location_point: { data: { bluetooth_driver_id_tag: bluetoothDriverIdTag } } } = response;
      bleDriverId = bluetoothDriverIdTag;
    }

    const newVehicle = await reAsignDriver(vehiclesList.find(vehicle => vehicle.trackingObjectUuid === mobileId), bleDriverId);

    if (!newVehicle) return;
    if (!newVehicle.isAsset && newVehicle.drivingStatus === 'driving' &&
      displayedTimestamp && getDateTimeDiff(displayedTimestamp, new Date(), 'hours') > 12) {
      set(newVehicle, 'drivingStatus', 'parked_with_alert'.toUpperCase());
    }
    const data = get(response, 'location_point.data', null);
    const drivingData = getRealtimeData(data, newVehicle);
    set(drivingData, 'location', location);
    set(drivingData, 'location.speed', get(response, 'location_point.location.speed'));
    set(drivingData, 'displayedTimestamp', displayedTimestamp);
    updateRealTimeDataInVehiclesMap(newVehicle.trackingObjectUuid, drivingData);
  };

  const getRealtimeData = (data, vehicle = {}) => {
    const engineTime = get(data, 'engine_time');
    const odometer = get(data, 'odo');
    const tires = get(data, 'tires');

    let isRaven = false;

    if (vehicle && vehicle.devicesInfo) {
      isRaven = vehicle.devicesInfo.find(device => device.deviceModel.make.toUpperCase() === 'RAVEN');
    }

    const odoWithOffset = isNumber(odometer) ? odometer + get(vehicle, 'deviceOdometerOffset', 0) : odometer;

    const engineHoursWithOffset = (!isRaven && isNumber(engineTime)) ?
      (engineTime / 3600) + get(vehicle, 'deviceEngineHoursOffset', 0) :
      engineTime;

    const buildObjectFromQuery = query => ({
      ...isNumber(query.engineHours) && { engineHours: { ...(vehicle.engineHours || {}), value: query.engineHours } },
      ...isNumber(query.odometer) && { odometer: { ...(vehicle.odometer || {}), value: query.odometer } },
      ...get(query, 'tires.length') && { tires: query.tires },
    });

    return buildObjectFromQuery({ engineHours: engineHoursWithOffset, odometer: odoWithOffset, tires });
  };

  const getAllTags = () => {
    client.query({
      query: getTagsExtendedQuery,
      fetchPolicy: 'network-only',
    })
      .then(res => {
        const { data: { extendedTags: tags } } = res;
        addAllTags(new Map(tags.map(tag => [tag.id, tag])));
      })
      .catch(e => ({ error: e }));
  };

  const getVehicles = async pagination => {
    const variables = {
      //pagination: { page, perPage: countPerPage },
      pagination,
      filter: {
        assigned: true,
      },
      sortBy: {
        field: DRIVING_STATUS_TIMESTAMP,
        direction: DESC,
      },

      groupBy: DAY,
      statusStats: TRIP_STATUSES_XG,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || moment().format('ZZ'),
    };
    return client.query({
      query: getVehiclesMapQuery,
      variables,
      fetchPolicy: 'network-only',
    }).then(({ data }) => data.vehicles);
  };

  const loadVehicles = async () => {
    try {
      clearVehiclesMap();

      const results = [];
      const { entities, pagination: { totalPages } } = await getVehicles({ page: 1, perPage: countPerPage });
      vehiclesList.push(...entities);

      for (let page = 2; page <= totalPages; page++) {
        results.push(getVehicles({ page, perPage: countPerPage }));
      }

      const entitiesResults = await Promise.all(results);

      entitiesResults.forEach(resp => vehiclesList.push(...resp.entities));

      return vehiclesList;
    } catch (e) {
      getErrorMessages(e).forEach(err => {
        alert(err, 'error');
        setGetVehiclesIsLoading(false);
      });
    }
  };

  return (<span style={{ display: 'none' }}></span>);
};
export default compose(
  memo,
  withApollo,
  withRouter
)(AllVehiclesRealTimeDataStore);

// export default AllVehiclesRealTimeDataStore;
