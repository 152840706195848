import { useState } from 'react';

// const PASSENGER_VEHICLES = ['PICKUP', 'PASSENGER CAR', 'MULTIPURPOSE PASSENGER VEHICLE (MPV)'];
const TRUCK_VEHICLES = ['TRUCK_TRACTOR', 'TRUCK-TRACTOR'];

const useTiresInfo = () => {
  const [maxPsi] = useState(0);
  const [minPsi] = useState(0);

  const getPsiAccordingVehicleType = vehicleType => {
    if (TRUCK_VEHICLES.includes(vehicleType)) {
      return {
        maxPsi: 117,
        minPsi: 95,
      };
    }
    return {
      maxPsi: 50,
      minPsi: 30,
    };
  };

  const getVehicleBodyTypeFn = async vehicleType => getPsiAccordingVehicleType(vehicleType ? vehicleType.toUpperCase() : 'TRUCK_TRACTOR');

  return {
    maxPsi,
    minPsi,
    getVehicleBodyTypeFn,
  };
};

export default useTiresInfo;

