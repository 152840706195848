/* global google */
import { get } from 'lodash';

export const getMapDimension = (map = {}) => {
  const mapContainer = map.current || {};
  return ({
    height: mapContainer.clientHeight,
    width: mapContainer.clientWidth,
  });
};

export const isValidLocation = location => {
  const lat = get(location, 'latitude', 0) || get(location, 'lat', 0) || 0;
  const lng = get(location, 'longitude', 0) || get(location, 'lng', 0) || 0;
  return (lat !== 0 || lng !== 0);
};

export const getRadiusFromBounds = bounds => {
  const from = bounds.getNorthEast();
  const to = bounds.getSouthWest();
  return google.maps.geometry.spherical.computeDistanceBetween(from, to) / 2;
};
