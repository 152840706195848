export const BOUNDARY_SETUP = 'BOUNDARY_SETUP';
export const DEVICE_ASSIGNMENT = 'DEVICE_ASSIGNMENT';
export const GROUP_SETUP = 'GROUP_SETUP';
export const LOCATION_SETUP = 'LOCATION_SETUP';
export const USER_INVITE = 'USER_INVITE';
export const NOTIFICATION_MANAGEMENT = 'NOTIFICATION_MANAGEMENT';
export const MAINTENANCE_ACCESS = 'MAINTENANCE_ACCESS';
export const REPORTS_ACCESS = 'REPORTS_ACCESS';
export const CAMERA_ACCESS = 'CAMERA_ACCESS';
export const VIDEO_ACCESS = 'VIDEO_ACCESS';
export const SAFETY_COACH = 'SAFETY_COACH';
export const ELD_ACCESS = 'ELD_ACCESS';
export const IO_INPUT_ACCESS = 'IO_INPUT_ACCESS';
export const TAG_SETUP = 'TAG_SETUP';
export const APOLLO_ELD_ACCESS = 'APOLLO_ELD_ACCESS';
export const FLEET_MANAGEMENT_ACCESS = 'FLEET_MANAGEMENT_ACCESS';
export const VEHICLE_VISIBILITY_BY_TAG = 'VEHICLE_VISIBILITY_BY_TAG';

export const MANAGEMENT_ACCESS = {
  boundarySetup: BOUNDARY_SETUP,
  deviceAssignment: DEVICE_ASSIGNMENT,
  locationSetup: LOCATION_SETUP,
  notificationManagement: NOTIFICATION_MANAGEMENT,
  maintenanceAccess: MAINTENANCE_ACCESS,
  reportsAccess: REPORTS_ACCESS,
  userInvite: USER_INVITE,
  cameraAccess: CAMERA_ACCESS,
  videoAccess: VIDEO_ACCESS,
  safetyCoach: SAFETY_COACH,
  eldAccess: ELD_ACCESS,
  ioInputAccess: IO_INPUT_ACCESS,
  tagSetup: TAG_SETUP,
  apolloEldAccess: APOLLO_ELD_ACCESS,
  fleetManagementAccess: FLEET_MANAGEMENT_ACCESS,
  vehicleVisibilityByTag: VEHICLE_VISIBILITY_BY_TAG,
};

export const OWNER_PRIVILEGES_ACCESS = [
  BOUNDARY_SETUP, DEVICE_ASSIGNMENT, GROUP_SETUP, LOCATION_SETUP, MAINTENANCE_ACCESS,
  NOTIFICATION_MANAGEMENT, REPORTS_ACCESS, USER_INVITE, CAMERA_ACCESS, VIDEO_ACCESS, SAFETY_COACH, ELD_ACCESS, VEHICLE_VISIBILITY_BY_TAG
];

export const OWNER_PRIVILEGES_PERMISSIONS = [
  'boundarySetup',
  'deviceAssignment',
  'groupSetup',
  'locationSetup',
  'notificationManagement',
  'maintenanceAccess',
  'reportsAccess',
  'userInvite',
  'cameraAccess',
  'videoAccess',
  'safetyCoach',
  'eldAccess',
  'apolloEldAccess',
  'vehicleVisibilityByTag',
];

export const PERMISSIONS = {
  boundarySetup: 'boundarySetup',
  deviceAssignment: 'deviceAssignment',
  groupSetup: 'groupSetup',
  locationSetup: 'locationSetup',
  userInvite: 'userInvite',
  maintenanceAccess: 'maintenanceAccess',
  reportsAccess: 'reportsAccess',
  notificationManagement: 'notificationManagement',
  vehicleManagement: 'vehicleManagement',
  vehicleDrive: 'vehicleDrive',
  cameraAccess: 'cameraAccess',
  videoAccess: 'videoAccess',
  safetyCoach: 'safetyCoach',
  eldAccess: 'eldAccess',
  gorillaEldAccess: 'gorillaEldAccess',
  apolloEldAccess: 'apolloEldAccess',
  tagSetup: 'tagSetup',
  ioInputAccess: 'ioInputAccess',
  fleetManagementAccess: 'fleetManagementAccess',
  vehicleVisibilityByTag: 'vehicleVisibilityByTag',
  all: 'all',
};

export const FEATURE_ACCESS = {
  eldEnabled: 'eldEnabled',
  maintenanceEnabled: 'maintenanceEnabled',
  cameraPolicyLevel: 'cameraPolicyLevel',
  safetyCoachEnabled: 'safetyCoachEnabled',
  all: 'all',
  gorillaEldEnabled: 'gorillaEldEnabled',
  apolloEldEnabled: 'apolloEldEnabled',
  fleetManagementAccess: 'fleetManagementAccess',
  vehicleVisibilityByTag: 'vehicleVisibilityByTag',
};
