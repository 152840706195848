import React, { useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  vehicle: {},
  drivingData: {
    engineHours: null,
    odometer: null,
    speed: null,
    startTime: null,
    startLocation: null,
    currentLocation: null,
    locationTimestamp: null,
    distance: null,
    estimatedFuel: null,
    fuelLevel: null,
    engineTemp: null,
    batteryVoltage: null,
    phoneEvents: 0,
    harshEvents: 0,
    refuelEvents: 0,
    engineDtcEvents: 0,
    tires: null,
  },
  deviceData: {
    status: null,
    statusTimestamp: null,
    statusLocation: null,
    statusLocationSource: null,
    statusLocationTimestamp: null,
    location: {},
    locationTimestamp: null,
    routeChunks: [],
    routeMaxTimestamp: null,
    newPoints: [],
    isRouteCleared: false,
  },
  drivingVehicles: new Map(),
};

const RealTimeContext = React.createContext(initialState);

const RealTimeProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <RealTimeContext.Provider value={{ state, dispatch }}>{props.children}</RealTimeContext.Provider>;
};

export { RealTimeContext, RealTimeProvider };
