export default (state = {}, action) => {
  switch (action.type) {
    case 'redux-oidc/USER_EXPIRING':
      window.localStorage.setItem('redirect_to', window.location.pathname);
      return state;
    case 'redux-oidc/USER_FOUND': {
      const { access_token, expires_at } = action.payload; //eslint-disable-line
      window.sessionStorage.removeItem('login_token');
      window.localStorage.setItem('access_token', access_token);
      window.localStorage.setItem('expires_at', (Number(expires_at) * 1000) - 30000);
      return state;
    }
    default:
      return state;
  }
};
