import React, { createContext, useState } from 'react';

const ParamsOptionsContext = createContext({});

const ParamsOptionsProvider = ({ children }) => {
  const [tagsCont, setTagsCont] = useState([]);
  const [types, setTypes] = useState([]);
  const values = {
    tagsCont,
    setTagsCont,
    typesContext: types,
    setTypesContext: setTypes,
  };

  return <ParamsOptionsContext.Provider value={values}>{children}</ParamsOptionsContext.Provider>;
};

export { ParamsOptionsContext, ParamsOptionsProvider };
