import
{
  ADD_UPDATE_VEHICLE_INFO,
  CLEAR_VEHICLES_STORE,
  UPDATE_VEHICLE_LIST,
  ADD_ALL_TAGS
} from './constant';

export const allVehiclesMapObject = new Map();
const reducer = (state, action) => {
  switch (action.type) {
    case ADD_UPDATE_VEHICLE_INFO: {
      const key = action.payload.id;
      const value = action.payload.data;
      if (allVehiclesMapObject.has(key)) {
        allVehiclesMapObject.set(key, {
          ...allVehiclesMapObject.get(key),
          ...value,
        });
      } else {
        allVehiclesMapObject.set(key, value);
      }
      return state;
    }
    case UPDATE_VEHICLE_LIST: {
      const updatedVehicleList = Array.from(allVehiclesMapObject.values());

      return {
        ...state,
        initialLoading: false,
        updatedVehicleList,
      };
    }
    case CLEAR_VEHICLES_STORE:
      allVehiclesMapObject.clear();
      // return {
      //   ...state,
      //   updatedVehicleList: [],
      //   allVehiclesMapObject: new Map(),
      // };
      return state;
    case ADD_ALL_TAGS: {
      const { allTags } = action.payload;
      return {
        ...state,
        allTags,
        tagsLoaded: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;
