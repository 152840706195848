import gql from 'graphql-tag';
import { Fragments } from 'requests/fragments';

export const generateRealtimeToken = gql(`
  query generateRealtimeToken {
    generateRealtimeToken {
      expiresAt
      token
    }
  }
`);

export const getTripPointsByVehicle = gql`
  query getTripPointsByVehicle($vehicleId: ID!, $tripId: ID!, $pagination: PaginationParams, $events: [PointEvent!], $types: [PointType!]) {
    vehicle(id: $vehicleId) {
      id
      trip(id: $tripId) {
        id
        points(filter: { events: $events, types: $types }, pagination: $pagination){
          entities {
            ...Point
          }
          pagination {
            ...Pagination
          }
        }
      }
    }
  }
  ${Fragments.PaginationFragment}
  ${Fragments.PointFragments}
`;

export const getTripPointsByDriver = gql`
  query getTripPointsByDriver($driverId: ID!, $tripId: ID!, $pagination: PaginationParams, $events: [PointEvent!]) {
    driver(id: $driverId) {
      id
      trip(id: $tripId) {
        id
        points(filter: { events: $events }, pagination: $pagination){
          entities {
            ...Point
          }
          pagination {
            ...Pagination
          }
        }
      }
    }
  }
  ${Fragments.PaginationFragment}
  ${Fragments.PointFragments}
`;

export const getRealtimeTripPointsByVehicle = gql`
  query getRealtimeTripPointsByVehicle(
    $vehicleId: ID!
    $pagination: PaginationParams
    $types: [PointType!]
    $timestamp: [PointTimestampFilter!]
  ) {
    vehicle(id: $vehicleId) {
      id
      points(
        pagination: $pagination,
        filter: { types: $types, timestamp: $timestamp }
      ){
        entities {
          ...Point
        }
        pagination {
          ...Pagination
        }
      }
    }
  }
  ${Fragments.PaginationFragment}
  ${Fragments.PointFragments}
`;

export const getTripRouteByVehicle = gql(`
  query getTripRouteByVehicle($vehicleId: ID!, $tripId: ID!) {
    vehicle(id: $vehicleId) {
      id
      trip(id: $tripId) {
        id
        route {
          id
          main
        }
      }
    }
  }
`);

export const getTripRouteByDriver = gql(`
  query getTripRouteByDriver($driverId: ID!, $tripId: ID!) {
    driver(id: $driverId) {
      id
      trip(id: $tripId) {
        id
        route {
          id
          main
        }
      }
    }
  }
`);

export const getTripsByVehicle = gql`
  query getTripsByVehicle(
    $id: ID!,
    $pagination: PaginationParams,
    $status: [TripStatus],
    $datetimerange: Datetimerange
  ) {
    vehicle(id: $id) {
      id
      trips(pagination: $pagination, status: $status, datetimerange: $datetimerange) {
        entities {
          ...Trip
        }
        
        pagination {
          ...Pagination
        }
      }
    }
  }
  ${Fragments.TripFragments}
  ${Fragments.PaginationFragment}
`;

export const getTripsByDriver = gql`
  query getTripsByDriver($id: ID!, $pagination: PaginationParams, $status: [TripStatus]) {
    driver(id: $id) {
      id
      trips(pagination: $pagination, status: $status) {
        entities {
          ...Trip
        }
        pagination {
          ...Pagination
        }
      }
    }
  }
  ${Fragments.TripFragments}
  ${Fragments.PaginationFragment}
`;

export const getVehicleTripInfo = gql`
  query getVehicleTripInfo($id: ID!, $tripId: ID!) {
    vehicle(id: $id) {
      id
      devicesInfo {
        id
        type
      }
      trip(id: $tripId) {
        idleTimeCalcLogic
        ...ExtendedTrip
      }
    }
  }
  ${Fragments.ExtendedTripFragments}  
`;

export const getDriverTripInfo = gql`
  query getDriverTripInfo($id: ID!, $tripId: ID!) {
    driver(id: $id) {
      id
      trip(id: $tripId) {
        ...ExtendedTrip
      }
    }
  }
  ${Fragments.ExtendedTripFragments} 
`;

export const createActionableItem = gql(`
  mutation createActionableItem($file: UploadOrNull, $notes: String!, $pointId: ID! ){
  createActionableItem(file: $file, notes: $notes, pointId: $pointId) {
    fileUrl
    id
    insertedAt
    notes
    fileName
   }
  }
`);

export const updateActionableItem = gql(`
  mutation updateActionableItem($file: UploadOrNull, $notes: String, $pointId: ID!, $id: ID! ){
    updateActionableItem(file: $file, notes: $notes, pointId: $pointId, id: $id) {
      fileUrl
      fileName
      id
      insertedAt
      notes
    }
  }
`);

export const deleteActionableItem = gql(`
  mutation deleteActionableItem($id: ID! ){
    deleteActionableItem(id: $id) {
      id
    }
  }
`);

export const getActionableItem = gql(`
  query actionableItem($id: Int) {
    actionableItem(id: $id) {
      id
      fileUrl
      fileName
      notes
      point {
        id
      }
    }
  }
`);

export const getVehicleStatsByPeriod = gql`
  query getVehicleStatsByPeriod($id: ID!, $groupBy: GroupByRange!, $status: [TripStatus], $timeZone: String) {
    vehicle(id: $id) {
      id,
      statsByPeriod(groupBy: $groupBy, tripStatus: $status, timeZone: $timeZone) {
        ...StatsByPeriod
      }
    }
  }
  ${Fragments.VehicleStatsFragment}
`;

export const getVehicleStatsToday = gql`
  query getVehicleStatsToday($id: ID!, $status: [TripStatus], $timeZone: String) {
    vehicle(id: $id) {
      id,
      statsToday(tripStatus: $status, timeZone: $timeZone) {
        ...StatsByPeriod
      }
    }
  }
  ${Fragments.VehicleStatsFragment}
`;

export const generateCustomRealtimeToken = gql(`
  query generateCustomRealtimeToken(
    $email: Email!
    $expiresAt: Int!
    $from: String!
    $notes: String
    $vehicleId: ID!
  ) {
    generateCustomRealtimeToken(
      email: $email
      expiresAt: $expiresAt
      from: $from
      notes: $notes
      vehicleId: $vehicleId
    ) {
        expiresAt
        token
    }
  }
`);

export const generateCustomRealtimeTokenMultipleVehicles = gql(`
  query generateCustomRealtimeTokenMultipleVehicles(
    $email: Email!
    $expiresAt: Int!
    $from: String!
    $notes: String
    $vehicleIds: [Int!]!
  ) {
    generateCustomRealtimeTokenMultipleVehicles(
      email: $email
      expiresAt: $expiresAt
      from: $from
      notes: $notes
      vehicleIds: $vehicleIds
    ) {
        expiresAt
        token
    }
  }
`);

export const getRawTripPoints = gql`
  query getRawTripPoints($vehicleId: ID!, $date: Datetimerange!) {
    rawPoints(vehicleId: $vehicleId, date: $date) {
      timestamp
      events
      data {
        fuelEconomy
      }
      location {
        speed
        latitude
        longitude
        address {
          city
          country
          countryCode
          fullAddress
          houseNumber
          latitude
          longitude
          postalCode
          state
          stateCode
          street
        }
      }
    }
  }
`;

export const exportRawPoints = gql`
mutation exportRawPoints($vehicleId: ID!, $date: Datetimerange!, $timeZone: String) {
    exportRawPoints(vehicleId: $vehicleId, date: $date, timeZone: $timeZone) {
      exportId
    }
  }
`;
export const exportRawPointsAsset = gql`
mutation exportRawPointsAsset($vehicleId: ID!, $date: Datetimerange!) {
  exportAssetsRawPoints(assetId: $vehicleId, date: $date) {
      exportId
    }
  }
  `;
export const getRawTripPointsAsset = gql`
  query getRawTripPointsAsset($vehicleId: ID!, $date: Datetimerange!) {
    rawPointsAssets(assetId: $vehicleId, date: $date) {
      timestamp
      events
      data {
        assetTemperature
      }
      location {
        speed
        latitude
        longitude
        address {
          city
          country
          countryCode
          fullAddress
          houseNumber
          latitude
          longitude
          postalCode
          state
          stateCode
          street
        }
      }
    }
  }
`;

export const getDateRangeWithAvailableVideos = gql`
query availableRavenMedia($deviceId: ID!) {
  ravenMediaAvailableFromToTimestamps(deviceId: $deviceId) {
    fromTimestamp
    toTimestamp
  }
}
`;

export const getCollisionTrips = gql`
query getCollisionTrips($vehicleId: ID!, $date: Datetime!){
  collisionTripsByVehicleDate(vehicleId: $vehicleId, date: $date){
    collisionTime
    tripId
    __typename
  }
}
`;
