import
{
  TRIP,
  TRIPS,
  TRIPMAP,
  TRIPMAP_UPDATE,
  TRIP_SELECTIONS,
  TRIP_POINTS_MAP,
  TRIP_POINTS_MAP_UPDATE,
  VEHICLE
} from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case TRIP: {
      return {
        ...state,
        tripsMap: {
          ...state.tripsMap,
          [action.tripItem.id]: { ...action.tripItem },
        },
      };
    }
    case TRIPS: {
      return {
        ...state,
        trips: action.trips,
        tripsLoading: action.loading || false,
        tripsMapReady: false,
      };
    }
    case TRIPMAP_UPDATE: {
      return {
        ...state,
        tripsMapReady: false,
      };
    }
    case TRIPMAP: {
      const { tripsMap } = state;
      const newTripsMap = Object.assign({}, tripsMap, action.tripsMap);
      return {
        ...state,
        tripsMap: newTripsMap,
        tripsMapReady: true,
      };
    }
    case TRIP_POINTS_MAP_UPDATE: {
      return {
        ...state,
        tripsPointsMapReady: false,
      };
    }
    case TRIP_POINTS_MAP: {
      const { tripsPointMap } = state;
      const newTripsMap = Object.assign({}, tripsPointMap, action.tripsPointMap);
      return {
        ...state,
        tripsPointMap: newTripsMap,
        tripsPointsMapReady: true,
      };
    }
    case TRIP_SELECTIONS: {
      const { tripIdMap = {} } = action;
      return { ...state, tripIdMap: { ...tripIdMap } };
    }
    case VEHICLE: {
      const { vehicle = {} } = action;
      return { ...state, vehicle: { ...vehicle } };
    }
    default: return state;
  }
};

export default reducer;
