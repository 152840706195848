import {
  UPDATE,
  UPDATE_LIST
} from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_LIST:
      return { ...state, geofenceList: action.geofenceList };
    case UPDATE:
      return { ...state, updateFlag: action.updateFlag };
    default:
      return state;
  }
};

export default reducer;
